<style scoped>
.rn-footer {
  position: relative;
}
.rn-footer {
  height: auto !important;
  margin-top: -2px;
  margin-bottom: 2px;
  display: -webkit-flex;
  display: flex;
  color: #999;
  flex-wrap: wrap;
  align-items: center;
}
.rn-footer span {
  font-size: 13px;
  line-height: normal;
  display: inline-block;
  text-overflow: ellipsis;
}

.rn-three-pic-content {
  margin-bottom: 10px;
  display: flex;
}
.rn-three-pic-wrap {
  width: 33.333%;
  height: 22.222%;
  line-height: 22.2222%;
  height: auto;
  padding-top: 22.2222%;
  display: inline-block;
  margin-right: 1px;
  vertical-align: top;
  position: relative;
}
.rn-three-pic-wrap {
  width: 33.105%;
  padding-top: 21.99%;
}
.activity-container .selectContent {
  flex: 1;
}
.selectIcon {
  background-size: 100% 100%;
}
p {
  margin-bottom: 0;
}
p.active {
  color: #f85959;
  font-weight: 700;
}
</style>
<template>
  <div>
    <div>
      <div class="rn-footer" style="display: flex">
        <div style="flex: 1; width: 100%; display: flex">
          <div
            style="flex: 1; color: #853c37"
            :style="clickNum && clickNum >= 10000 ? { color: 'red' } : ''"
          >
            <span>
              <i>{{ dealClick(clickNum) }}</i>
            </span>
          </div>
          <!-- color:rgb(108 133 55); -->
          <div style="flex: 1; display: inline-block" v-if="answerTime">
            <span class="view_time">{{ answerTime }}分钟</span>
          </div>
          <div style="flex: 1; display: inline-block" v-if="eachAnswerTime">
            <span class="view_time">{{ eachAnswerTime }}s/题</span>
          </div>
        </div>
        <div>
          <div style="display: inline-block" v-if="createdTime">
            <span class="view_time">{{ createdTime | dateFormat }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

moment.locale("zh-cn");

export default {
  name: "rnExamFooter",
  data() {
    return {};
  },
  props: {
    clickNum: {
      type: Number,
      default: 0,
    },
    createdTime: {
      type: String,
      default: "",
    },
    // 试卷限时
    answerTime: {
      type: Number,
      default: 0,
    },
    // 试题限时
    eachAnswerTime: {
      type: Number,
      default: 0,
    },
  },
  methods: {
    dealClick(num) {
      if (!num) {
        return "";
      }
      if (num >= 10000) {
        return `${Math.ceil(parseFloat(num / 1000, 10)) / 10}万 点击`;
      }
      if (num < 10000) {
        return `${num}点击`;
      }
    },
  },
  filters: {
    dateFormat(time) {
      return moment(time).format("YYYY-MM-DD HH:mm");
    },
  },
};
</script>
