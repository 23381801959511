<style scoped>
</style>
<template>
  <div v-show="isShow">
    <div
      v-if="loadStatus"
      style="width: 100%; padding: 10px 0; text-align: center; color: white"
      @click="$emit('refresh')"
    >
      {{ text }}
    </div>
    <div
      v-if="!loadStatus"
      style="width: 100%; padding: 10px 0; text-align: center; color: white"
    >
      没有更多数据了
    </div>
  </div>
</template>

<script>
import { setTimeout } from "timers";

export default {
  name: "loadMoreData",
  data() {
    return {
      scloll: true,
      text: "没有更多数据了",
    };
  },
  props: {
    loadStatus: {
      type: Boolean,
      default: true,
    },
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    // 向下滑动
    scrollBottom() {
      const ds = document.body.scrollTop || document.documentElement.scrollTop;
      // console.log(
      //   "向下滑动",
      //   ds,
      //   document.body.clientHeight,
      //   window.screen.height
      // );
      if (document.body.clientHeight > window.screen.height) {
        this.text = "加载中...";
      }
      // 向下滑动;
      if (window.screen.height + ds + 50 >= document.body.clientHeight) {
        if (this.scloll) {
          this.$emit("refresh");
          this.scloll = false;
          setTimeout(() => {
            this.scloll = true;
          }, 1000);
        }
      }
    },
    // scrollBottom() {
    //   var ds =
    //     document.getElementById("app").scrollTop ||
    //     document.documentElement.scrollTop;
    //   if (document.getElementById("app").clientHeight > window.screen.height) {
    //     this.text = "加载中...";
    //   }
    //   // 向下滑动
    //   if (
    //     window.screen.height + ds + 50 >=
    //     document.getElementById("appScroll").clientHeight
    //   ) {
    //     if (this.scloll) {
    //       this.$emit("refresh");
    //       this.scloll = false;
    //       setTimeout(() => {
    //         this.scloll = true;
    //       }, 1000);
    //     }
    //   }
    // }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollBottom);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollBottom);
  },
};
</script>
