<style scoped>
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.hisback {
  position: fixed;
  z-index: 999;
  top: 0;
  padding: 0 20px;
  width: 100%;
  height: 3rem;
  /* background: var(--searchColor); */
  background: white;
  color: #aaa;
  line-height: 3rem;
  font-size: 1.2rem;
  box-shadow: 0px 3px 3px rgba(30, 155, 204, 0.05);
  display: flex;
}
.back_box {
  width: 100%;
  height: 3rem;
}

.center {
  display: inline-block;
  width: 0px;
  height: 100%;
  vertical-align: middle;
}
.splice {
  width: 100%;
  height: 10px;
  background: #f1f1f1;
  margin-top: 10px;
}
.problem_list_his {
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  padding-bottom: 0;
}

.list_con .list_con_one {
  padding: 10px;
  padding-top: 15px;
  padding-left: 12px;
  margin: 4px 0;
  box-sizing: border-box;
  width: 100%;
  position: relative;
  box-shadow: 1px 2px 3px 4px rgba(30, 155, 204, 0.05);
}
</style>
<template>
  <div>
    <div class="hisback">
      <div style="text-align: left">
        <span
          style="display: inline-block; font-weight: bold; color: #1c1c1c"
          @click="back"
        >
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
          返回
        </span>
      </div>
      <div style="flex: 1; text-align: center">历史答题</div>
    </div>
    <div class="back_box"></div>
    <div class="problem_list_his">
      <div class="list_con">
        <div v-for="(item, index) in prolist" :key="index" class="list_con_one">
          <div class="con_one_left" style="text-align: left">
            <h3>{{ getActivityTitle(item.cyleType) }}</h3>
          </div>
          <div
            style="
              display: flex;
              margin-top: 10px;
              font-size: 14px;
              color: #a49696;
              text-align: left;
            "
          >
            <div style="flex: 1">
              得分: <span style="color: red"> {{ item.score }}</span> &nbsp; 分
            </div>
            <div style="flex: 1; text-align: right">
              用时: {{ formatDuring(item.timeMs) }}
            </div>
          </div>
          <rn-exam-footer
            style="margin-top: 10px"
            :clickNum="item.clickNum"
            :createdTime="item.updated ? item.updated : ''"
            :answerTime="item.answerTime ? parseInt(item.answerTime) : 0"
            :eachAnswerTime="
              item.eachAnswerTime ? parseInt(item.eachAnswerTime) : 0
            "
          ></rn-exam-footer>
          <!-- <div
            style="display:flex;margin-top:10px;font-size:14px;color:#a49696;text-align:left;"
          >
            <div style="flex:1;">
              得分: <span style="color:red"> {{ item.score }}</span> &nbsp; 分
            </div>
            <div style="flex:1;text-align:right;">
              用时: {{ formatDuring(item.timeMs) }}
            </div>
          </div> -->
          <!-- <div
            style="display:flex;margin-top:10px;font-size:14px;color:#a49696;text-align:left;"
          >
            <div style="flex:1;color:red;">{{ item.score }}分</div>
            <div style="flex:1;text-align:right;">
              {{ formatDuring(item.timeMs) }}
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <load-more
      @refresh="onRefresh"
      :load-status="isLoadMore"
      :isShow="loadMoreShow"
    ></load-more>
  </div>
</template>

<script>
import globalData from "../api/globalData";
import { wxConPost } from "../api/httpApi";
import _ from "lodash";
import loadMore from "../widgets/loadMoreData.vue";
import rnExamFooter from "../widgets/rnExamFooter.vue";

export default {
  name: "answerHisList",
  data() {
    return {
      proTitle: "",
      prolist: [],
      isLoadMore: true,
      loadMoreShow: false,
      activityId: "",
    };
  },
  computed: {},
  components: { loadMore, rnExamFooter },
  methods: {
    getActivityTitle(type) {
      if (!type) {
        return "专项答题";
      } else {
        return type;
      }
    },
    // 回退
    back() {
      this.$router.push({ path: "/activity-main" });
    },
    // loadMore
    async onRefresh() {
      const query = {
        limit: 0,
        skip: this.prolist.length,
        id: this.activityId,
      };
      try {
        const ret = await wxConPost(
          "/services/gttt-answer-exam/exam/examAnsweList",
          query
        );

        if (ret.data.length == 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        } else {
          this.loadMoreShow = true;

          this.prolist = this.prolist.concat(ret.data);
        }
      } catch (e) {
        this.loadMoreShow = true;
        this.isLoadMore = false;
      }
    },

    // 获取更多题目
    async getMore() {
      try {
        const ret = await wxConPost(
          "/services/gttt-answer-exam/exam/examAnsweList",
          { limit: 20, skip: 0, id: this.activityId }
        );
        this.prolist = ret.data;
        if (this.prolist.length <= 0) {
          this.loadMoreShow = true;
          this.isLoadMore = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    formatDuring(time) {
      let minutes = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = (time % (1000 * 60)) / 1000;
      // 格式 00天 00时 00分 00秒
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }
      return minutes + "分/" + seconds + "秒";
    },
  },
  created() {
    this.activity = globalData.getActivity();
    if (!_.isEmpty(this.activity)) {
      this.activityId = this.activity.id;
      this.getMore(this.proTitle);
    }
  },
};
</script>
